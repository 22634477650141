import AccordionDetails from '@mui/material/AccordionDetails'
import AccordionSummary from '@mui/material/AccordionSummary'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import AlertTitle from '@mui/material/AlertTitle'
import Typography from '@mui/material/Typography'
import Accordion from '@mui/material/Accordion'
import SvgIcon from '@mui/material/SvgIcon'
import Button from '@mui/material/Button'
import Alert from '@mui/material/Alert'
import Stack from '@mui/material/Stack'
import Link from '@mui/material/Link'
import { useMemo } from 'react'
import Bowser from 'bowser'

import LocationDeniedIcon from '../../images/location-denied.svg'
import CameraDeniedIcon from '../../images/no_camera_access.svg'

const browser = Bowser.getParser(window.navigator.userAgent)
enum MediaPermissionsErrorType {
  GeolocationPositionError = 'GeolocationPositionError',
  SystemPermissionDenied = 'SystemPermissionDenied',
  UserPermissionDenied = 'UserPermissionDenied',
  CouldNotStartVideoSource = 'CouldNotStartVideoSource',
  Generic = 'Generic',
}

interface ErrorMessageProps {
  error: IError
}
interface IError {
  name: string
  message: string
  type: MediaPermissionsErrorType
}
export type PermissionError = GeolocationPositionError | Error

interface TryAgainButtonProps {
  text?: string
}
function TryAgainButton({ text = 'Recheck' }: TryAgainButtonProps): React.JSX.Element {
  return (
    <Button
      onClick={() => {
        window.location.reload()
      }}
      fullWidth
    >
      {text}
    </Button>
  )
}
function ErrorMessage({ error }: ErrorMessageProps): React.JSX.Element | null {
  if (!error.message) return null

  return (
    <Accordion>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls='error-panel-content'
        id='error-panel-header'
      >
        <Typography variant='caption' color='red'>
          Error Details
        </Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Typography variant='caption'>
          {error.name || 'Error'}: {error.message}
        </Typography>
      </AccordionDetails>
    </Accordion>
  )
}
function UserDeniedMessage({ error }: ErrorMessageProps): React.JSX.Element {
  return (
    <Alert severity='error' sx={{ width: '100%', '& .MuiAlert-message': { flexGrow: 1 } }}>
      <AlertTitle>
        <Typography variant='h5'>Camera is blocked</Typography>
      </AlertTitle>
      <Stack spacing={2}>
        <Typography>
          App requires access to your camera.{' '}
          {browser.getBrowserName() !== 'Safari' && (
            <Typography component='span'>
              Click the camera blocked icon{' '}
              <SvgIcon viewBox='0 -6 24 24'>
                <CameraDeniedIcon />
              </SvgIcon>{' '}
              in your browser&apos;s address bar.
            </Typography>
          )}
        </Typography>
        {ENV_NAME === 'Development' && <ErrorMessage error={error} />}
        <TryAgainButton />
      </Stack>
    </Alert>
  )
}
function SystemDeniedMessage({ error }: ErrorMessageProps): React.JSX.Element {
  const settingsDataByOS = {
    macOS: {
      name: 'System Preferences',
      link: 'x-apple.systempreferences:com.apple.preference.security?Privacy_Camera',
    },
  }

  return (
    <Alert severity='error' sx={{ width: '100%', '& .MuiAlert-message': { flexGrow: 1 } }}>
      <AlertTitle>
        <Typography variant='h5'>Can&apos;t use your camera</Typography>
      </AlertTitle>
      <Stack spacing={2}>
        <Typography>
          Your browser might not have access to your camera. To fix this problem, open{' '}
          {
            // @ts-expect-error Need to index with this
            settingsDataByOS[browser.getOSName()] ? (
              <Link
                onClick={() => {
                  window.open(
                    // @ts-expect-error Need to index with this
                    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
                    settingsDataByOS[browser.getOSName()].link,
                    '_blank',
                  )
                }}
              >
                {
                  // @ts-expect-error Need to index with this
                  settingsDataByOS[browser.getOSName()].name
                }
              </Link>
            ) : (
              'Settings'
            )
          }
          .
        </Typography>
        {ENV_NAME === 'Development' && <ErrorMessage error={error} />}
        <TryAgainButton />
      </Stack>
    </Alert>
  )
}
function InUseMessage({ error }: ErrorMessageProps): React.JSX.Element {
  return (
    <Alert severity='error' sx={{ width: '100%', '& .MuiAlert-message': { flexGrow: 1 } }}>
      <AlertTitle>
        <Typography variant='h5'>Can&apos;t start your camera</Typography>
      </AlertTitle>
      <Stack spacing={2}>
        <Typography>
          Another application (Zoom, Webex) or browser tab (Google Meet, Messenger Video) might
          already be using your webcam. Please turn off other cameras before proceeding.
        </Typography>
        {ENV_NAME === 'Development' && <ErrorMessage error={error} />}
        <TryAgainButton text='Retry' />
      </Stack>
    </Alert>
  )
}
function GeoLocationMessage({ error }: ErrorMessageProps): React.JSX.Element {
  return (
    <Alert severity='error' sx={{ width: '100%', '& .MuiAlert-message': { flexGrow: 1 } }}>
      <AlertTitle>
        <Typography variant='h5'>Location is blocked</Typography>
      </AlertTitle>
      <Stack spacing={2}>
        <Typography>
          App requires access to your location.{' '}
          {browser.getBrowserName() !== 'Safari' && (
            <Typography component='span'>
              Click the location blocked icon{' '}
              <SvgIcon viewBox='0 0 55 85'>
                <LocationDeniedIcon />
              </SvgIcon>{' '}
              in your browser&apos;s address bar.
            </Typography>
          )}
        </Typography>
        {ENV_NAME === 'Development' && <ErrorMessage error={error} />}
        <TryAgainButton />
      </Stack>
    </Alert>
  )
}
interface GenericMessageProps extends ErrorMessageProps {
  actions?: React.ReactNode
  defaultMessage?: string
}
function GenericMessage({
  actions,
  error,
  defaultMessage,
}: GenericMessageProps): React.JSX.Element {
  return (
    <Alert severity='error' sx={{ width: '100%', '& .MuiAlert-message': { flexGrow: 1 } }}>
      <AlertTitle>
        <Typography variant='h5'>Error</Typography>
      </AlertTitle>
      <Stack spacing={2}>
        <Typography>{defaultMessage ?? error.message}</Typography>
        {ENV_NAME === 'Development' && <ErrorMessage error={error} />}
        {actions && actions}
      </Stack>
    </Alert>
  )
}

interface MediaPermissionErrorViewerProps {
  actions?: React.ReactNode
  error: PermissionError
  genericMessage?: string
}
export default function MediaPermissionErrorViewer({
  actions,
  error: initError,
  genericMessage,
}: MediaPermissionErrorViewerProps): React.JSX.Element {
  const browserName = browser.getBrowserName()
  const error = useMemo<IError>(() => {
    if (initError instanceof GeolocationPositionError) {
      const { message } = initError

      return {
        name: 'GeolocationPermissionError',
        message,
        type: MediaPermissionsErrorType.GeolocationPositionError,
      }
    }

    const { name, message } = initError

    if (browserName === 'Chrome') {
      if (name === 'NotAllowedError') {
        if (message === 'Permission denied by system') {
          return { name, message, type: MediaPermissionsErrorType.SystemPermissionDenied }
        } else if (message === 'Permission denied') {
          return { name, message, type: MediaPermissionsErrorType.UserPermissionDenied }
        }
      } else if (name === 'NotReadableError') {
        return { name, message, type: MediaPermissionsErrorType.CouldNotStartVideoSource }
      }
    } else if (browserName === 'Safari') {
      if (name === 'NotAllowedError') {
        return { name, message, type: MediaPermissionsErrorType.UserPermissionDenied }
      }
    } else if (browserName === 'Microsoft Edge') {
      if (name === 'NotAllowedError') {
        return { name, message, type: MediaPermissionsErrorType.UserPermissionDenied }
      } else if (name === 'NotReadableError') {
        return { name, message, type: MediaPermissionsErrorType.CouldNotStartVideoSource }
      }
    } else if (browserName === 'Firefox') {
      if (name === 'NotFoundError') {
        return { name, message, type: MediaPermissionsErrorType.SystemPermissionDenied }
      } else if (name === 'NotReadableError') {
        return { name, message, type: MediaPermissionsErrorType.SystemPermissionDenied }
      } else if (name === 'NotAllowedError') {
        return { name, message, type: MediaPermissionsErrorType.UserPermissionDenied }
      } else if (name === 'AbortError') {
        return { name, message, type: MediaPermissionsErrorType.CouldNotStartVideoSource }
      }
    }

    return { name, message, type: MediaPermissionsErrorType.Generic }
  }, [browserName, initError])

  switch (error.type) {
    case MediaPermissionsErrorType.SystemPermissionDenied:
      return <SystemDeniedMessage error={error} />
    case MediaPermissionsErrorType.GeolocationPositionError:
      return <GeoLocationMessage error={error} />
    case MediaPermissionsErrorType.UserPermissionDenied:
      return <UserDeniedMessage error={error} />
    case MediaPermissionsErrorType.CouldNotStartVideoSource:
      return <InUseMessage error={error} />
    default:
      return <GenericMessage actions={actions} error={error} defaultMessage={genericMessage} />
  }
}
