export default class ImageNetworkError extends Error {
  public request: ImageUploadRequest
  public response: UhaulErrorResponse
  public status: number

  public constructor(status: number, response: UhaulErrorResponse, request: ImageUploadRequest) {
    super('Image upload error')

    this.response = response
    this.status = status
    this.request = request
  }
}
