import { Box, type DialogProps, IconButton, TextField, Typography } from '@mui/material'
import SendIcon from '@mui/icons-material/Send'
import { useRef, useState } from 'react'
import dayjs from 'dayjs'
import useSWR from 'swr'

import { decodeHtmlEntity as decodeHtmlEntities, isEmptyString } from '@util/functions'
import { getGetRequest, getPostRequest } from '@util/request'
import AgentMessage from '@c/chat/agent-message'
import UserMessage from '@c/chat/user-message'
import Dialog from '@c/dialog'

export const SEND_MESSAGE_URL = '/SelfDispatch/LiveVerification/SendMessage'
export const CHAT_MESSAGE_URL = '/SelfDispatch/LiveVerification/GetChatMessages/false'

const DEFAULT_MESSAGE = {
  sender: 'agent',
  name: 'Agent',
  message: 'Hello how can I help you?',
}

export interface ChatResponse {
  id: string
  sender: string
  senderForDisplay: { value: string }
  timeSentLocal: string
  message: {
    value: string
  }
}

async function fetcher(url: string): Promise<ChatMessage[]> {
  const messages: ChatMessage[] = [{ ...DEFAULT_MESSAGE }]
  const request = getGetRequest()
  const response = await request<ChatResponse[]>(url)

  if (Array.isArray(response.data)) {
    response.data.forEach((chat) => {
      if (chat.id !== 'Approved') {
        messages.push({
          sender: chat.sender.toLowerCase(),
          name: chat.senderForDisplay.value,
          // TODO - Need to change timeSentLocal to a Dayjs.  Pay once not every re-render.
          timeSentLocal: chat.timeSentLocal,
          message: decodeHtmlEntities(chat.message.value),
        })
      }
    })
  }

  return messages
}

interface ChatDialogProps extends DialogProps {
  text?: string
  onClose?: (
    event: React.MouseEvent<HTMLButtonElement>,
    reason: 'backdropClick' | 'escapeKeyDown' | 'titleCloseButtonClick',
  ) => void
}

export default function ChatDialog({ open, text, ...other }: ChatDialogProps): React.JSX.Element {
  const [userMessage, setUserMessage] = useState('')
  const { data: messages, mutate } = useSWR(open ? CHAT_MESSAGE_URL : null, fetcher, {
    refreshInterval: 5000,
    revalidateOnFocus: false,
    fallbackData: [],
  })
  const loading = !messages?.length
  const messagesEndRef = useRef<HTMLDivElement>(null)

  async function handleChatSend(): Promise<void> {
    const request = getPostRequest()
    const newUserMessage = {
      sender: 'customer',
      timeSentLocal: dayjs().format(),
      message: userMessage.trim(),
    }
    const newMessages = [...messages, { ...newUserMessage, sending: true }]

    setUserMessage('')
    await mutate(newMessages, false)
    await request(SEND_MESSAGE_URL, newUserMessage)
    scrollToBottom()
    await mutate()
  }
  function handleMessageKeyDown(event: React.KeyboardEvent): void {
    if (event.key === 'Enter' && !event.shiftKey && !isEmptyString(userMessage)) {
      event.preventDefault()
      handleChatSend()
    }
  }
  function handleMessageChange(event: React.ChangeEvent<HTMLInputElement>): void {
    const { value } = event.target

    setUserMessage(value)
  }
  function scrollToBottom(): void {
    if (messagesEndRef.current) {
      messagesEndRef.current.scrollIntoView({ behavior: 'auto' })
    }
  }

  return (
    <Dialog
      open={open}
      maxWidth='lg'
      fullWidth
      {...other}
      TransitionProps={{
        onEntered: scrollToBottom,
      }}
      title='Chat with Agent'
      actions={
        <>
          <TextField
            sx={{ flexGrow: 1 }}
            size='small'
            label='Enter Message'
            variant='outlined'
            multiline
            maxRows={4}
            onKeyDown={handleMessageKeyDown}
            onChange={handleMessageChange}
            value={userMessage}
            autoFocus
          />
          <IconButton
            size='small'
            aria-label='chat'
            onClick={handleChatSend}
            disabled={isEmptyString(userMessage)}
          >
            <SendIcon />
          </IconButton>
        </>
      }
    >
      <Box>
        {loading && <Typography>Loading Chat</Typography>}
        {text}
        {messages?.map((message, index) => {
          if (message.sender === 'customer') {
            return <UserMessage key={index} {...message} />
          }

          return <AgentMessage key={index} {...message} />
        })}
        <div ref={messagesEndRef} />
      </Box>
    </Dialog>
  )
}
