export enum STATES_AND_PROVINCES_TYPE {
  PROVINCE = 'Canadian Provinces',
  STATE = 'States',
}
export const STATES_AND_PROVINCES = [
  // Provinces
  { name: 'Alberta', abbreviation: 'AB', type: STATES_AND_PROVINCES_TYPE.PROVINCE },
  { name: 'British Columbia', abbreviation: 'BC', type: STATES_AND_PROVINCES_TYPE.PROVINCE },
  { name: 'Manitoba', abbreviation: 'MB', type: STATES_AND_PROVINCES_TYPE.PROVINCE },
  { name: 'New Brunswick', abbreviation: 'NB', type: STATES_AND_PROVINCES_TYPE.PROVINCE },
  { name: 'Newfoundland', abbreviation: 'NL', type: STATES_AND_PROVINCES_TYPE.PROVINCE },
  { name: 'Northwest Territory', abbreviation: 'NT', type: STATES_AND_PROVINCES_TYPE.PROVINCE },
  { name: 'Nova Scotia', abbreviation: 'NS', type: STATES_AND_PROVINCES_TYPE.PROVINCE },
  { name: 'Ontario', abbreviation: 'ON', type: STATES_AND_PROVINCES_TYPE.PROVINCE },
  { name: 'Prince Edward Island', abbreviation: 'PE', type: STATES_AND_PROVINCES_TYPE.PROVINCE },
  { name: 'Québec', abbreviation: 'QC', type: STATES_AND_PROVINCES_TYPE.PROVINCE },
  { name: 'Saskatchewan', abbreviation: 'SK', type: STATES_AND_PROVINCES_TYPE.PROVINCE },
  { name: 'Yukon', abbreviation: 'YT', type: STATES_AND_PROVINCES_TYPE.PROVINCE },
  // States
  { name: 'Alabama', abbreviation: 'AL', type: STATES_AND_PROVINCES_TYPE.STATE },
  { name: 'Alaska', abbreviation: 'AK', type: STATES_AND_PROVINCES_TYPE.STATE },
  { name: 'Arizona', abbreviation: 'AZ', type: STATES_AND_PROVINCES_TYPE.STATE },
  { name: 'Arkansas', abbreviation: 'AR', type: STATES_AND_PROVINCES_TYPE.STATE },
  { name: 'California', abbreviation: 'CA', type: STATES_AND_PROVINCES_TYPE.STATE },
  { name: 'Colorado', abbreviation: 'CO', type: STATES_AND_PROVINCES_TYPE.STATE },
  { name: 'Connecticut', abbreviation: 'CT', type: STATES_AND_PROVINCES_TYPE.STATE },
  { name: 'District of Columbia', abbreviation: 'DC', type: STATES_AND_PROVINCES_TYPE.STATE },
  { name: 'Delaware', abbreviation: 'DE', type: STATES_AND_PROVINCES_TYPE.STATE },
  { name: 'Florida', abbreviation: 'FL', type: STATES_AND_PROVINCES_TYPE.STATE },
  { name: 'Georgia', abbreviation: 'GA', type: STATES_AND_PROVINCES_TYPE.STATE },
  { name: 'Hawaii', abbreviation: 'HI', type: STATES_AND_PROVINCES_TYPE.STATE },
  { name: 'Idaho', abbreviation: 'ID', type: STATES_AND_PROVINCES_TYPE.STATE },
  { name: 'Illinois', abbreviation: 'IL', type: STATES_AND_PROVINCES_TYPE.STATE },
  { name: 'Indiana', abbreviation: 'IN', type: STATES_AND_PROVINCES_TYPE.STATE },
  { name: 'Iowa', abbreviation: 'IA', type: STATES_AND_PROVINCES_TYPE.STATE },
  { name: 'Kansas', abbreviation: 'KS', type: STATES_AND_PROVINCES_TYPE.STATE },
  { name: 'Kentucky', abbreviation: 'KY', type: STATES_AND_PROVINCES_TYPE.STATE },
  { name: 'Louisiana', abbreviation: 'LA', type: STATES_AND_PROVINCES_TYPE.STATE },
  { name: 'Maine', abbreviation: 'ME', type: STATES_AND_PROVINCES_TYPE.STATE },
  { name: 'Maryland', abbreviation: 'MD', type: STATES_AND_PROVINCES_TYPE.STATE },
  { name: 'Massachusetts', abbreviation: 'MA', type: STATES_AND_PROVINCES_TYPE.STATE },
  { name: 'Michigan', abbreviation: 'MI', type: STATES_AND_PROVINCES_TYPE.STATE },
  { name: 'Minnesota', abbreviation: 'MN', type: STATES_AND_PROVINCES_TYPE.STATE },
  { name: 'Mississippi', abbreviation: 'MS', type: STATES_AND_PROVINCES_TYPE.STATE },
  { name: 'Missouri', abbreviation: 'MO', type: STATES_AND_PROVINCES_TYPE.STATE },
  { name: 'Montana', abbreviation: 'MT', type: STATES_AND_PROVINCES_TYPE.STATE },
  { name: 'Nebraska', abbreviation: 'NE', type: STATES_AND_PROVINCES_TYPE.STATE },
  { name: 'Nevada', abbreviation: 'NV', type: STATES_AND_PROVINCES_TYPE.STATE },
  { name: 'New Hampshire', abbreviation: 'NH', type: STATES_AND_PROVINCES_TYPE.STATE },
  { name: 'New Jersey', abbreviation: 'NJ', type: STATES_AND_PROVINCES_TYPE.STATE },
  { name: 'New Mexico', abbreviation: 'NM', type: STATES_AND_PROVINCES_TYPE.STATE },
  { name: 'New York', abbreviation: 'NY', type: STATES_AND_PROVINCES_TYPE.STATE },
  { name: 'North Carolina', abbreviation: 'NC', type: STATES_AND_PROVINCES_TYPE.STATE },
  { name: 'North Dakota', abbreviation: 'ND', type: STATES_AND_PROVINCES_TYPE.STATE },
  { name: 'Ohio', abbreviation: 'OH', type: STATES_AND_PROVINCES_TYPE.STATE },
  { name: 'Oklahoma', abbreviation: 'OK', type: STATES_AND_PROVINCES_TYPE.STATE },
  { name: 'Oregon', abbreviation: 'OR', type: STATES_AND_PROVINCES_TYPE.STATE },
  { name: 'Pennsylvania', abbreviation: 'PA', type: STATES_AND_PROVINCES_TYPE.STATE },
  { name: 'Rhode Island', abbreviation: 'RI', type: STATES_AND_PROVINCES_TYPE.STATE },
  { name: 'South Carolina', abbreviation: 'SC', type: STATES_AND_PROVINCES_TYPE.STATE },
  { name: 'South Dakota', abbreviation: 'SD', type: STATES_AND_PROVINCES_TYPE.STATE },
  { name: 'Tennessee', abbreviation: 'TN', type: STATES_AND_PROVINCES_TYPE.STATE },
  { name: 'Texas', abbreviation: 'TX', type: STATES_AND_PROVINCES_TYPE.STATE },
  { name: 'Utah', abbreviation: 'UT', type: STATES_AND_PROVINCES_TYPE.STATE },
  { name: 'Vermont', abbreviation: 'VT', type: STATES_AND_PROVINCES_TYPE.STATE },
  { name: 'Virginia', abbreviation: 'VA', type: STATES_AND_PROVINCES_TYPE.STATE },
  { name: 'Washington', abbreviation: 'WA', type: STATES_AND_PROVINCES_TYPE.STATE },
  { name: 'West Virginia', abbreviation: 'WV', type: STATES_AND_PROVINCES_TYPE.STATE },
  { name: 'Wisconsin', abbreviation: 'WI', type: STATES_AND_PROVINCES_TYPE.STATE },
  { name: 'Wyoming', abbreviation: 'WY', type: STATES_AND_PROVINCES_TYPE.STATE },
]
export enum APP_CONTEXT {
  AUTHORIZED_DRIVER = 'AuthorizedDriver',
  COUNTER_DRIVER = 'CounterDriver',
  CUSTOMER_DISPATCH = 'CustomerHandoff',
  SELF_DISPATCH = 'SelfDispatch',
  SELF_RETURN = 'SelfReturn',
}
export const UHAUL_DOMAIN =
  ENV_NAME === 'Development' ? 'https://uhauld.com' : 'https://www.uhaul.com'
export const BASE_ADDRESS: Address = {
  address: '',
  city: '',
  state: '',
  zipCode: '',
}
export enum HybridLocationEvent {
  DidGetLocation = 'HybridLocationEventDidGetLocation',
  GetLocationFailed = 'HybridLocationEventGetLocationFailed',
}
export enum HybridCameraEvent {
  CaptureFailed = 'HybridCameraEventCaptureFailed',
  DidCapture = 'HybridCameraEventDidCapture',
  DidReadCreditCard = 'HybridCameraEventDidReadCreditCard',
  DidReadDriversLicense = 'HybridCameraEventDidReadDriversLicense',
  DidScan = 'HybridCameraEventDidScan',
  DidTakePicture = 'HybridCameraEventDidTakePicture',
  ReadCreditCardFailed = 'HybridCameraEventReadCreditCardFailed',
  ReadDriversLicenseFailed = 'HybridCameraEventReadDriversLicenseFailed',
  ScanFailed = 'HybridCameraEventScanFailed',
  TakePictureFailed = 'HybridCameraEventTakePictureFailed',
}

export enum HybridMessageEvent {
  DidReceiveMessage = 'HybridMessageEventDidReceiveMessage',
  SendMessageToAppFailed = 'HybridMessageEventSendMessageToAppFailed',
  DidGetDeviceDetails = 'HybridMessageEventDidGetDeviceDetails',
}

export enum ImageFileType {
  Hookup = 1,
  Propane = 2,
  MasterDataFeedEntity = 3,
  GypsyMoth = 4,
  Damage = 5,
  Cleanliness = 6,
  Dashboard = 7,
  UBox = 8,
  Equipment = 9,
  Receipt = 10,
  BillOfLading = 11,
  AuthorityCertificate = 12,
  CargoInsurance = 13,
  LiabilityInsurance = 14,
  W9Form = 15,
  ConfidentialityAgreement = 16,
  CarrierAgreement = 17,
  SuretyBond = 18,
  EquipmentRecoveryCondition = 19,
  ReimbursementReceipt = 20,
  DamageDriverRear = 21,
  DamageDoorsRoof = 22,
  DamageCabTailgate = 23,
  DamagePassengerFront = 24,
  UBoxHomeDeliveryPlacement = 25,
  UBoxTruckDeliveryDropoffLocation = 26,
  LoadOrUnload = 27,
  DriverSide = 28,
  PassengerSide = 29,
  Front = 30,
  Rear = 31,
  CabInterior = 32,
  BodyOrBedInterior = 33,
  Roof = 34,
  Planogram = 35,
  HitchProduct = 36,
  ShowroomSales = 37,
  POSOther = 38,
  HitchVideo = 39,
  CMTVideo = 40,
  Incident = 41,
  ConditionReportPhoto = 42,
  ConditionReportDocument = 43,
  EquipmentRecoveryDocument = 44,
  DealerPhoto = 45,
  DealerVideo = 46,
  RPCVideo = 47,
  ContractOther = 48,
  LogbookDVIRSignature = 49,
  MeaningfulAssurance = 50,
  MovingHelpProvider = 51,
  MovingHelpHeadshots = 52,
  MovingHelpSafeLoad = 53,
  MovingHelpCustomer = 54,
  MovingHelpSupport = 55,
  CMTDocument = 56,
}
