import { Box, CircularProgress, Paper, Typography } from '@mui/material'
import relativeTime from 'dayjs/plugin/relativeTime'
import PersonIcon from '@mui/icons-material/Person'
import dayjs from 'dayjs'

dayjs.extend(relativeTime)

export default function UserMessage({
  sending,
  timeSentLocal,
  name,
  message,
}: ChatMessage): React.JSX.Element {
  return (
    <Paper sx={{ p: 2 }} variant='outlined'>
      <Box display='flex' flexDirection='row' alignItems='center' justifyContent='flex-end'>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-between',
            flexGrow: 1,
            pr: 1,
          }}
        >
          {sending && <CircularProgress size={20} color='secondary' />}
          <Box flexGrow={1} ml={sending ? 2 : 0}>
            <Typography sx={{ color: 'textSecondary' }}>
              {dayjs(timeSentLocal).fromNow()}
            </Typography>
          </Box>
          <Typography variant='h6'>{name}</Typography>
        </Box>
        <PersonIcon fontSize='large' />
      </Box>
      <Typography align='right' sx={{ mr: 4.75 }}>
        {message}
      </Typography>
    </Paper>
  )
}
