import QuoteIcon from '@mui/icons-material/FormatQuote'
import Grid from '@mui/material/Unstable_Grid2/Grid2'
import StarIcon from '@mui/icons-material/Star'
import green from '@mui/material/colors/green'
import ReactPlayer from 'react-player/youtube'
import red from '@mui/material/colors/red'
import { Typography } from '@mui/material'

import PickupIcon from '@icons/icon-pickup-your-truck.svg'
import ReturnIcon from '@icons/icon-return-truck.svg'
import ReserveIcon from '@icons/icon-reserve-now.svg'
import MoveIcon from '@icons/icon-manage-move.svg'
import Container, { Paper } from '@c/container'
import { getUhaulLink } from '@util/functions'
import ButtonDialog from '@c/button-dialog'
import AvatarLink from '@c/avatar-link'
import renderView from '@views/loader'

function ShareView(): React.JSX.Element {
  return (
    <Container>
      <Paper>
        <Grid container spacing={3}>
          <Grid sx={{ display: { xs: 'none', md: 'block' } }} md={7}>
            <img src='/images/woman-renting-uhaul-truck.png' style={{ height: '100%' }} />
          </Grid>

          <Grid xs={12} md={5} sx={{ textAlign: 'center' }}>
            <Paper
              variant='outlined'
              sx={{
                backgroundColor: 'background.shadowBox',
                padding: 1,
              }}
            >
              <Typography variant='h4'>Get Your Truck</Typography>
              <Typography variant='h4' color='primary' gutterBottom>
                Using only Your Phone!
              </Typography>
              <img src='/images/logo-truck-share.png' />
              <blockquote>
                <Typography>
                  <QuoteIcon
                    sx={{ transform: 'rotate(180deg)' }}
                    style={{ color: green[800] }}
                    fontSize='large'
                  />
                  <Typography
                    fontWeight='fontWeightBold'
                    display='inline'
                    color='primary'
                    component='span'
                  >
                    Fantastic automated experience.{' '}
                  </Typography>
                  I decided to try the self-check-out on my phone.{' '}
                  <Typography
                    fontWeight='fontWeightBold'
                    color='primary'
                    display='inline'
                    component='span'
                  >
                    It was easy...
                  </Typography>
                </Typography>
              </blockquote>
              <Typography>
                <Typography fontWeight='fontWeightBold' component='span'>
                  James L.&nbsp;
                </Typography>
                <Typography
                  sx={{
                    fontStyle: 'italic',
                    fontSize: '0.75rem',
                  }}
                  component='span'
                >
                  rented a cargo van
                </Typography>
                <Typography
                  sx={{
                    fontStyle: 'italic',
                    fontSize: '0.75rem',
                  }}
                  component='span'
                  display='block'
                >
                  {' '}
                  U-Haul of Golden Ring, Baltimore, MD
                </Typography>
              </Typography>
              <StarIcon sx={{ color: red[500] }} fontSize='small' />
              <StarIcon sx={{ color: red[500] }} fontSize='small' />
              <StarIcon sx={{ color: red[500] }} fontSize='small' />
              <StarIcon sx={{ color: red[500] }} fontSize='small' />
              <StarIcon sx={{ color: red[500] }} fontSize='small' />
            </Paper>
          </Grid>
        </Grid>
      </Paper>
      <Paper>
        <Grid container spacing={3}>
          <Grid xs={12} md={6}>
            <AvatarLink
              title='Reserve Now'
              titleProps={{
                sx: {
                  color: green[500],
                  fontWeight: 'bold',
                },
              }}
              message='Need to rent a truck? Reserve now! Pick up anytime, day or night using your phone.  Online help is available.'
              buttonText='View Options'
              buttonProps={{ variant: 'outlined' }}
              href={getUhaulLink('/Truck-Rentals/')}
              icon={ReserveIcon}
            />
          </Grid>
          <Grid xs={12} md={6}>
            <AvatarLink
              title='Manage Your Move'
              titleProps={{
                sx: {
                  color: green[500],
                  fontWeight: 'bold',
                },
              }}
              message='Having a U-Haul account allows you to pick up and return your truck (and much more) using your phone.'
              buttonText='Create Account'
              buttonProps={{ variant: 'outlined' }}
              href={getUhaulLink('/Account/Create/')}
              icon={MoveIcon}
            />
          </Grid>
          <Grid xs={12} md={6}>
            <AvatarLink
              title='Pick Up Your Truck'
              titleProps={{
                sx: {
                  color: green[500],
                  fontWeight: 'bold',
                },
              }}
              message="Sign into your account and access your truck using your phone. Once you complete the Live Verification approval process and a few other steps, you'll be on your way."
              buttonText='Get Started'
              buttonProps={{ variant: 'outlined' }}
              href={getUhaulLink('/SelfPickUpConversion/OrderLookup/')}
              iconViewBox='0 0 100 100'
              icon={PickupIcon}
            />
          </Grid>
          <Grid xs={12} md={6}>
            <AvatarLink
              title='Return Your Truck or Trailer'
              titleProps={{
                sx: {
                  color: green[500],
                  fontWeight: 'bold',
                },
              }}
              message="Ready to return your truck? It's easy to do, using your phone. Follow the prompts and in no time you'll be done."
              buttonText='Get Started'
              buttonProps={{ variant: 'outlined' }}
              href={getUhaulLink('/Orders/')}
              icon={ReturnIcon}
            />
          </Grid>
        </Grid>
      </Paper>
      <Paper>
        <ButtonDialog
          title='U-Haul Truck Share 24/7® Tutorial'
          buttonContent='U-Haul Truck Share 24/7® Tutorial'
          ButtonProps={{ fullWidth: true, variant: 'outlined' }}
          buttonType='video'
          DialogProps={{ maxWidth: 'lg' }}
          DialogContentProps={{
            sx: {
              display: 'flex',
              alignItems: 'center',
              justifyItems: 'center',
              p: 1,
            },
          }}
          actionType='none'
        >
          <ReactPlayer url='https://www.youtube.com/embed/Z-8CcguL1Kk?rel=0' controls playing />
        </ButtonDialog>
      </Paper>
    </Container>
  )
}

renderView(ShareView, true)
