/* eslint-disable deprecation/deprecation */
/* eslint-disable no-useless-escape */
'use strict'
let HybridCameraModule
;(function (HybridCameraModule) {
  /**
   *
   *
   * @class HybridCamera
   */
  class HybridCamera {
    /**
     *
     *
     * @static
     * @param {string} type
     * @param {boolean} showOverlay
     * @param {Function} callback
     * @memberof HybridCamera
     */
    static scanCallback(type, showOverlay = false, shouldReturnPicture = false, callback) {
      const config = new Scanner(type, showOverlay, shouldReturnPicture)
      if (AppChecker.isIOSApp()) {
        const promise = window.webkit.messageHandlers.launchCameraForScanningCallback.postMessage(
          JSON.stringify(config),
        )
        promise
          .then((json) => {
            const parsedJson = JSON.parse(json)
            // Decode barcode data from base-64
            const decodedData = atob(parsedJson.data)
            parsedJson.data = decodedData
            callback(null, parsedJson)
          })
          .catch((error) => {
            callback(Object.assign(Error, JSON.parse(error)), null)
          })
      } else if (AppChecker.isAndroidApp()) {
        // TODO: Callback needs to decode barcode data from base-64
        window.Android.launchCameraForScanningCallback(
          JSON.stringify(config),
          '(' + callback.toString() + ')',
        )
      }
    }

    /**
     *
     *
     * @static
     * @param {string} type
     * @param {boolean} showOverlay
     * @memberof HybridCamera
     */
    static scan(type, showOverlay = false, shouldReturnPicture = false) {
      const config = new Scanner(type, showOverlay, shouldReturnPicture)
      if (AppChecker.isIOSApp()) {
        window.webkit.messageHandlers.launchCameraForScanning.postMessage(JSON.stringify(config))
      } else if (AppChecker.isAndroidApp()) {
        window.Android.launchCameraForScanning(JSON.stringify(config))
      }
    }

    /**
     *
     *
     * @static
     * @param {string} json
     * @memberof HybridCamera
     */
    static didScan(json) {
      const parsedJson = JSON.parse(json)
      // Decode barcode data from base-64
      const decodedData = atob(parsedJson.data)
      parsedJson.data = decodedData
      const didScan = new CustomEvent(HybridCamera.Event.DidScan, {
        detail: parsedJson,
      })
      dispatchEvent(didScan)
    }

    /**
     *
     *
     * @static
     * @param {string} json
     * @memberof HybridCamera
     */
    static didScanFailed(json) {
      const error = Object.assign(Error, JSON.parse(json))
      dispatchEvent(new CustomEvent(HybridCamera.Event.ScanFailed, { detail: error }))
    }

    /**
     *
     *
     * @static
     * @param {string} type
     * @param {Function} callback
     * @memberof HybridCamera
     */
    static readCallback(type, callback) {
      const config = new Reader(type)
      if (AppChecker.isIOSApp()) {
        const promise = window.webkit.messageHandlers.launchCameraForReadingCallback.postMessage(
          JSON.stringify(config),
        )
        promise
          .then((json) => {
            callback(null, JSON.parse(json))
          })
          .catch((error) => {
            callback(Object.assign(Error, JSON.parse(error)), null)
          })
      } else if (AppChecker.isAndroidApp()) {
        window.Android.launchCameraForReadingCallback(
          JSON.stringify(config),
          '(' + callback.toString() + ')',
        )
      }
    }

    /**
     *
     *
     * @static
     * @param {string} type
     * @memberof HybridCamera
     */
    static read(type) {
      const config = new Reader(type)
      if (AppChecker.isIOSApp()) {
        window.webkit.messageHandlers.launchCameraForReading.postMessage(JSON.stringify(config))
      } else if (AppChecker.isAndroidApp()) {
        window.Android.launchCameraForReading(JSON.stringify(config))
      }
    }

    /**
     *
     *
     * @static
     * @param {string} json
     * @memberof HybridCamera
     */
    static didReadCreditCard(json) {
      const didReadCreditCard = new CustomEvent(HybridCamera.Event.DidReadCreditCard, {
        detail: JSON.parse(json),
      })
      dispatchEvent(didReadCreditCard)
    }

    /**
     *
     *
     * @static
     * @param {string} json
     * @memberof HybridCamera
     */
    static didReadCreditCardFailed(json) {
      const error = Object.assign(Error, JSON.parse(json))
      dispatchEvent(new CustomEvent(HybridCamera.Event.ReadCreditCardFailed, { detail: error }))
    }

    /**
     *
     *
     * @static
     * @param {string} json
     * @memberof HybridCamera
     */
    static didReadDriversLicense(json) {
      const didReadDriversLicense = new CustomEvent(HybridCamera.Event.DidReadDriversLicense, {
        detail: JSON.parse(json),
      })
      dispatchEvent(didReadDriversLicense)
    }

    /**
     *
     *
     * @static
     * @param {string} json
     * @memberof HybridCamera
     */
    static didReadDriversLicenseFailed(json) {
      const error = Object.assign(Error, JSON.parse(json))
      dispatchEvent(new CustomEvent(HybridCamera.Event.ReadDriversLicenseFailed, { detail: error }))
    }

    /**
     *
     *
     * @static
     * @param {string} [type="default"]
     * @param {boolean} [showOverlay=false]
     * @param {(number | undefined)} [maxDimension=undefined]
     * @param {(number | undefined)} [compressionPercentage=undefined]
     * @param {(string[] | undefined)} [metadata=undefined]
     * @memberof HybridCamera
     */
    static takePictureCallback(
      type = 'default',
      showOverlay = false,
      maxDimension = undefined,
      compressionPercentage = undefined,
      metadata = undefined,
      callback,
    ) {
      const config = new Picture(type, showOverlay, maxDimension, compressionPercentage, metadata)
      if (AppChecker.isIOSApp()) {
        const promise = window.webkit.messageHandlers.launchCameraCallback.postMessage(
          JSON.stringify(config),
        )
        promise
          .then((json) => {
            callback(null, JSON.parse(json))
          })
          .catch((error) => {
            callback(Object.assign(Error, JSON.parse(error)), null)
          })
      } else if (AppChecker.isAndroidApp()) {
        window.Android.launchCameraCallback(JSON.stringify(config), '(' + callback.toString() + ')')
      }
    }

    /**
     *
     *
     * @static
     * @param {string} [type="default"]
     * @param {boolean} [showOverlay=false]
     * @param {(number | undefined)} [maxDimension=undefined]
     * @param {(number | undefined)} [compressionPercentage=undefined]
     * @param {(string[] | undefined)} [metadata=undefined]
     * @memberof HybridCamera
     */
    static takePicture(
      type = 'default',
      showOverlay = false,
      maxDimension = undefined,
      compressionPercentage = undefined,
      metadata = undefined,
    ) {
      const config = new Picture(type, showOverlay, maxDimension, compressionPercentage, metadata)
      if (AppChecker.isIOSApp()) {
        window.webkit.messageHandlers.launchCamera.postMessage(JSON.stringify(config))
      } else if (AppChecker.isAndroidApp()) {
        window.Android.launchCamera(JSON.stringify(config))
      }
    }

    /**
     *
     *
     * @static
     * @param {string} json
     * @memberof HybridCamera
     */
    static didTakePicture(json) {
      const didTakePicture = new CustomEvent(HybridCamera.Event.DidTakePicture, {
        detail: JSON.parse(json),
      })
      dispatchEvent(didTakePicture)
    }

    /**
     *
     *
     * @static
     * @param {string} json
     * @memberof HybridCamera
     */
    static didTakePictureFailed(json) {
      const error = Object.assign(Error, JSON.parse(json))
      dispatchEvent(new CustomEvent(HybridCamera.Event.TakePictureFailed, { detail: error }))
    }

    /**
     *
     *
     * @static
     * @param {string} type
     * @param {Function} callback
     * @memberof HybridCamera
     */
    static captureCallback(type, callback) {
      const config = new Capture(type)
      if (AppChecker.isIOSApp()) {
        const promise = window.webkit.messageHandlers.captureCallback.postMessage(
          JSON.stringify(config),
        )
        promise
          .then((json) => {
            callback(null, JSON.parse(json))
          })
          .catch((error) => {
            callback(Object.assign(Error, JSON.parse(error)), null)
          })
      } else if (AppChecker.isAndroidApp()) {
        window.Android.captureCallback(JSON.stringify(config), '(' + callback.toString() + ')')
      }
    }

    /**
     *
     *
     * @static
     * @param {string} type
     * @memberof HybridCamera
     */
    static capture(type) {
      const config = new Capture(type)
      if (AppChecker.isIOSApp()) {
        window.webkit.messageHandlers.capture.postMessage(JSON.stringify(config))
      } else if (AppChecker.isAndroidApp()) {
        window.Android.capture(JSON.stringify(config))
      }
    }

    /**
     *
     *
     * @static
     * @param {string} json
     * @memberof HybridCamera
     */
    static didCapture(json) {
      const didCapture = new CustomEvent(HybridCamera.Event.DidCapture, {
        detail: JSON.parse(json),
      })
      dispatchEvent(didCapture)
    }

    /**
     *
     *
     * @static
     * @param {string} json
     * @memberof HybridCamera
     */
    static didCaptureFailed(json) {
      const error = Object.assign(Error, JSON.parse(json))
      dispatchEvent(new CustomEvent(HybridCamera.Event.CaptureFailed, { detail: error }))
    }

    /**
     *
     *
     * @static
     * @description Determines whether the current host is in a hybrid app.
     * @returns {boolean}
     * @memberof HybridCamera
     */
    static isInHybridApp() {
      return (AppChecker.isAndroidApp() || AppChecker.isIOSApp()) && AppChecker.isUHaulEnterprise()
    }

    /**
     *
     *
     * @static
     * @description Returns the semantic version string of the hybrid library.
     * @returns {string}
     * @memberof HybridCamera
     */
    static getVersion() {
      return '2.0.0'
    }
  }
  /**
   *
   *
   * @static
   * @type {{
   *          DidScan: string,
   *          ScanFailed: string,
   *          DidTakePicture: string,
   *          TakePictureFailed: string,
   *          DidReadCreditCard: string,
   *          ReadCreditCardFailed: string,
   *          DidReadDriversLicense: string,
   *          ReadDriversLicenseFailed: string,
   *          DidCapture: string,
   *          CaptureFailed: string
   *       }}
   * @memberof HybridCamera
   */
  HybridCamera.Event = {
    DidScan: 'HybridCameraEventDidScan',
    ScanFailed: 'HybridCameraEventScanFailed',
    DidTakePicture: 'HybridCameraEventDidTakePicture',
    TakePictureFailed: 'HybridCameraEventTakePictureFailed',
    DidReadCreditCard: 'HybridCameraEventDidReadCreditCard',
    ReadCreditCardFailed: 'HybridCameraEventReadCreditCardFailed',
    DidReadDriversLicense: 'HybridCameraEventDidReadDriversLicense',
    ReadDriversLicenseFailed: 'HybridCameraEventReadDriversLicenseFailed',
    DidCapture: 'HybridCameraEventDidCapture',
    CaptureFailed: 'HybridCameraEventCaptureFailed',
  }
  HybridCameraModule.HybridCamera = HybridCamera
  window.HybridCamera = HybridCamera
  // Utility Classes
  class AppChecker {
    /*
           ====Native Application Checking Mechanism====
        */
    // isIOSApp, isAndroidApp, isApp, isMobile, isDesktop.
    // For iOS webView check
    static isIOSApp() {
      return window.webkit != null
    }

    // For android webView check
    static isAndroidApp() {
      return window.Android != null
    }

    // An app on iOS or Android
    static isApp() {
      return this.isIOSApp() || this.isAndroidApp()
    }

    // Enterprise app identified by the custom user-agent
    static isUHaulEnterprise() {
      return AppChecker.userAgent().isUHaulEnterprise
    }

    static isMobile() {
      let isMobile = false // initiate as false
      // device detection
      if (
        /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|ipad|iris|kindle|Android|Silk|lge |maemo|midp|mmp|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows (ce|phone)|xda|xiino/i.test(
          navigator.userAgent,
        ) ||
        /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
          navigator.userAgent.substr(0, 4),
        )
      ) {
        isMobile = true
      }
      return isMobile
    }

    static isDesktop() {
      let isDesktop = true // initiate as false
      // device detection
      if (
        /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|ipad|iris|kindle|Android|Silk|lge |maemo|midp|mmp|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows (ce|phone)|xda|xiino/i.test(
          navigator.userAgent,
        ) ||
        /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
          navigator.userAgent.substr(0, 4),
        )
      ) {
        isDesktop = false
      }
      return isDesktop
    }

    // This function returns the platform information as a string
    static platform() {
      let platform = ''
      let isMobile = false // initiate as false
      // device detection
      if (
        /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|ipad|iris|kindle|Android|Silk|lge |maemo|midp|mmp|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows (ce|phone)|xda|xiino/i.test(
          navigator.userAgent,
        ) ||
        /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
          navigator.userAgent.substr(0, 4),
        )
      ) {
        isMobile = true
      }
      if (isMobile) {
        if (window.Android != null) {
          platform = 'Android Native'
        } else if (window.webkit != null) {
          platform = 'IOS Native'
        } else {
          platform = 'Mobile Web'
        }
      } else {
        platform = 'Desktop Web'
      }
      return platform
    }

    static userAgent() {
      const userAgent = {
        isAndroid: /Android/.test(navigator.userAgent),
        isCordova: !!window.cordova,
        isEdge: /Edge/.test(navigator.userAgent),
        isFirefox: /Firefox/.test(navigator.userAgent),
        isChrome: /Google Inc/.test(navigator.vendor),
        isChromeIOS: /CriOS/.test(navigator.userAgent),
        isChromiumBased: !!window.chrome && !/Edge/.test(navigator.userAgent),
        isIE: /Trident/.test(navigator.userAgent),
        isIOS: /(iPhone|iPad|iPod)/.test(navigator.platform),
        isWebkit: window.webkit != null,
        isOpera: /OPR/.test(navigator.userAgent),
        isSafari: /Safari/.test(navigator.userAgent) && !/Chrome/.test(navigator.userAgent),
        isUHaulEnterprise: /UHaul\/Enterprise/.test(navigator.userAgent),
      }
      return userAgent
    }
  }
  window.HybridCamera.AppChecker = AppChecker
  class Picture {
    /**
     *Creates an instance of Picture.
     * @param {string} type - values: default, profile, driversLicense
     * @param {boolean} showOverlay - defaults to true
     * @param {number} maxDimension
     * @param {string[]} metaData - values: coordinates, timestamp, position, resolution
     * @memberof Picture
     */
    constructor(type, showOverlay, maxDimension, compressionPercentage, metaData) {
      this.type = type
      this.showOverlay = showOverlay
      this.maxDimension = maxDimension
      // Clamping compressionPercentage to range 0-100
      // eslint-disable-next-line eqeqeq
      if (compressionPercentage != undefined) {
        if (compressionPercentage < 0) {
          compressionPercentage = 0
        } else if (compressionPercentage > 100) {
          compressionPercentage = 100
        }
      }
      this.compressionPercentage = compressionPercentage
      this.metaData = metaData
    }
  }
  HybridCameraModule.Picture = Picture
  class Reader {
    /**
     * Creates an instance of Reader.
     * @param {string} type - values: creditCard, driversLicense
     * @memberof Reader
     */
    constructor(type) {
      this.type = type
    }
  }
  HybridCameraModule.Reader = Reader
  class Scanner {
    /**
     *Creates an instance of Scanner.
     * @param {string[]} type
     * @param {boolean} [showOverlay]
     * @param {boolean} [shouldReturnPicture]
     * @memberof Scanner
     */
    constructor(type, showOverlay, shouldReturnPicture) {
      this.type = type
      this.showOverlay = showOverlay
      this.shouldReturnPicture = shouldReturnPicture
    }
  }
  HybridCameraModule.Scanner = Scanner
  class Capture {
    /**
     *Creates an instance of Capture.
     * @param {string} type - values: check, driversLicense
     * @memberof Capture
     */
    constructor(type) {
      this.type = type
    }
  }
  HybridCameraModule.Capture = Capture
})(HybridCameraModule || (HybridCameraModule = {}))
