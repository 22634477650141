import { Box, Button, SvgIcon, Typography } from '@mui/material'

import type { ButtonProps, TypographyProps } from '@mui/material'

interface AvatarLinkProps {
  message: string
  buttonProps: ButtonProps
  buttonText: string
  title: string
  titleProps: TypographyProps
  href?: string
  icon: React.FunctionComponent
  iconViewBox?: string
}
export default function AvatarLink({
  message,
  buttonProps,
  buttonText = 'Click Here',
  title,
  titleProps,
  href,
  icon,
  iconViewBox = '0 0 125 125',
}: AvatarLinkProps): React.JSX.Element {
  return (
    <Box sx={{ display: 'flex', flexDirection: 'row' }}>
      <Box sx={{ width: 75, display: { xs: 'none', md: 'block' } }}>
        <SvgIcon
          sx={{ marginTop: 1, height: 75, width: 75 }}
          component={icon}
          viewBox={iconViewBox}
        />
      </Box>
      <Box sx={{ display: 'flex', flexDirection: 'column', flexGrow: 1, p: 1 }}>
        <Typography variant='h5' {...titleProps}>
          {title}
        </Typography>
        <Typography paragraph>{message}</Typography>
        {!!href && (
          <Button href={href} {...buttonProps}>
            {buttonText}
          </Button>
        )}
      </Box>
    </Box>
  )
}
