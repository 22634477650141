/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  Alert,
  BottomNavigation,
  BottomNavigationAction,
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Fab,
  IconButton,
  List,
  ListItemButton,
  ListItemText,
  ListSubheader,
  Typography,
} from '@mui/material'
import { createContext, useCallback, useContext, useMemo, useState } from 'react'
import ContractIcon from '@mui/icons-material/HistoryEdu'
import ToolIcon from '@mui/icons-material/Construction'
import ModelIcon from '@mui/icons-material/DataObject'
import CloseIcon from '@mui/icons-material/Close'
import LogIcon from '@mui/icons-material/Note'

import { Paper } from '../components/container'

const MobileToolsContext = createContext<MobileToolsProviderValue | undefined>(undefined)

interface MobileToolsProviderValue {
  log: (message: any, ...otherMessages: any[]) => void
}

interface MobileToolsProviderProps {
  children: React.ReactNode
}
function MobileToolsProvider({ children }: MobileToolsProviderProps): React.JSX.Element {
  const [open, setOpen] = useState(false)
  const [logs, setLogs] = useState<string[]>([])
  const [viewIndex, setViewIndex] = useState(0)

  const log = useCallback((message: any, ...otherMessages: any[]): void => {
    const logMessages = [message, ...otherMessages]

    setLogs((prev): string[] => {
      const newLogs = logMessages.map((lMessage) => JSON.stringify(lMessage, null, 2))

      return [...prev, ...newLogs]
    })
  }, [])
  const value = useMemo(() => {
    return { log }
  }, [log])
  function getView(): React.ReactNode {
    switch (viewIndex) {
      case 0:
        return (
          <Box>
            {logs.map((log, index) => (
              <Paper variant='outlined' key={index} sx={{ mt: 1, overflow: 'auto' }}>
                <Typography component='pre' sx={{ fontFamily: 'monospace' }}>
                  {log}
                </Typography>
              </Paper>
            ))}
            {!logs.length && <Alert severity='info'>No Logs</Alert>}
          </Box>
        )
      case 1:
        return window?.modelData ? (
          <Paper variant='outlined' sx={{ mt: 1, overflow: 'auto' }}>
            <Typography component='pre' sx={{ fontFamily: 'monospace' }}>
              {JSON.stringify(window.modelData, null, 2)}
            </Typography>
          </Paper>
        ) : (
          <Alert severity='info'>No View Model Defined</Alert>
        )
      case 2:
        return (
          <List
            subheader={
              <ListSubheader sx={{ textAlign: 'center' }} component='div'>
                Development Only
              </ListSubheader>
            }
          >
            <Divider />
            <ListItemButton component='a' href='/'>
              <ListItemText primary='Home' />
            </ListItemButton>
            <ListItemButton
              onClick={() => {
                window.location.href = '/Demo'
              }}
            >
              <ListItemText primary='Scenarios' />
            </ListItemButton>
            <ListSubheader>Sandbox</ListSubheader>
            <ListItemButton
              sx={{ pl: 4 }}
              onClick={() => {
                window.location.href = '/Dev/Tool/Sandbox'
              }}
            >
              <ListItemText primary='Views' />
            </ListItemButton>

            <ListSubheader>POCs</ListSubheader>
            <ListItemButton sx={{ pl: 4 }} component='a' href='/Dev/POC/Scandit-Verifier'>
              <ListItemText primary='Scandit Verifier' />
            </ListItemButton>

            <ListSubheader>Tools</ListSubheader>
            <ListItemButton sx={{ pl: 4 }} component='a' href='/Dev/Tool/eat-viewer'>
              <ListItemText primary='EAT Viewer' />
            </ListItemButton>
            <ListItemButton sx={{ pl: 4 }} component='a' href='/Dev/Tool/hybrid-checker'>
              <ListItemText primary='Hybrid Checker' />
            </ListItemButton>
            <ListItemButton sx={{ pl: 4 }} component='a' href='/Dev/Tool/image-checker'>
              <ListItemText primary='Image Checker' />
            </ListItemButton>
            <ListItemButton sx={{ pl: 4 }} component='a' href='/Contract/Start'>
              <ListItemText primary='Contract Tools' />
            </ListItemButton>
          </List>
        )
    }

    return undefined
  }

  return (
    <MobileToolsContext.Provider value={value}>
      {children}
      <Fab
        sx={{ position: 'fixed', bottom: 16, left: [16, 280] }}
        onClick={() => {
          setOpen(true)
        }}
      >
        <ToolIcon />
      </Fab>
      <Dialog
        open={open}
        onClose={() => {
          setOpen(false)
        }}
        sx={{
          '& .MuiDialog-paperFullScreen': {
            m: 0,
          },
        }}
        fullScreen
      >
        <DialogTitle sx={{ display: 'flex', alignItems: 'center' }}>
          <Typography variant='h4' component='span' flexGrow={1}>
            Native Debug Tools
          </Typography>
          <IconButton
            onClick={() => {
              setOpen(false)
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent dividers sx={{ p: 0.5 }}>
          {getView()}
        </DialogContent>
        <DialogActions sx={{ p: 0 }}>
          <BottomNavigation
            value={viewIndex}
            sx={{ display: 'flex', width: '100%' }}
            onChange={(event, newValue: number) => {
              setViewIndex(newValue)
            }}
          >
            <BottomNavigationAction
              sx={{ flexGrow: 1, maxWidth: 'none' }}
              label='Logs'
              icon={<LogIcon />}
              showLabel
            />
            <BottomNavigationAction
              sx={{ flexGrow: 1, maxWidth: 'none' }}
              label='View Model'
              icon={<ModelIcon />}
              showLabel
            />
            <BottomNavigationAction
              sx={{ flexGrow: 1, maxWidth: 'none' }}
              label='Tools'
              icon={<ToolIcon />}
              showLabel
            />
            <BottomNavigationAction
              sx={{ flexGrow: 1, maxWidth: 'none' }}
              label='Contract'
              icon={<ContractIcon />}
              href='/Contract/Start'
              showLabel
            />
          </BottomNavigation>
        </DialogActions>
      </Dialog>
    </MobileToolsContext.Provider>
  )
}

function useMobileTools(): MobileToolsProviderValue {
  const context = useContext(MobileToolsContext)

  if (context === undefined) {
    throw new Error('useMobileTools must be used within a MobileToolsProvider')
  }

  return context
}

export { MobileToolsProvider, useMobileTools }
