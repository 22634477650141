import { Box, Button, Stack, Typography } from '@mui/material'
import LanguageIcon from '@mui/icons-material/Language'
import { useMemo } from 'react'

import { Paper } from '@c/container'

const LANGUAGE = {
  FRENCH: 'french',
  SPANISH: 'spanish',
  ENGLISH: 'english',
}

export default function Footer(): React.JSX.Element {
  const isNative = !!(window.localStorage && localStorage.getItem('UhaulNative'))
  const URLS = useMemo(() => {
    const { hostname, port: portPart, protocol, pathname } = window.location
    const port = portPart ? `:${portPart}` : ''
    const host = hostname.replace(/^fr\.|^es\./gi, '')
    const language = hostname.startsWith('fr')
      ? LANGUAGE.FRENCH
      : hostname.startsWith('es')
        ? LANGUAGE.SPANISH
        : LANGUAGE.ENGLISH

    return {
      CURRENT_LANG: language,
      EN: `${protocol}//${host}${port}${pathname}`,
      FR: `${protocol}//fr.${host}${port}${pathname}`,
      ES: `${protocol}//es.${host}${port}${pathname}`,
    }
  }, [])

  return (
    <footer>
      <Paper
        square
        elevation={0}
        sx={{
          m: 0,
          minHeight: '4em',
          color: (theme) => theme.palette.getContrastText(theme.palette.background.footer),
          backgroundColor: 'background.footer',
        }}
      >
        <Stack spacing={1}>
          <Typography align='center' sx={{ fontSize: '.75rem' }}>
            {`Copyright © ${SERVER_YEAR} U-Haul and Web Team Associates${
              ENV_NAME === 'Development' ? ` - ${ENV_NAME}` : ''
            }`}
            {VERSION ? ` ${VERSION}` : ''}
            {ENV_NAME === 'Development' && isNative ? ' Native' : ''}
            {` - ${SERVER_NAME}`}
          </Typography>
          <Box display='flex' alignItems='center' justifyContent='center' gap={2}>
            {URLS.CURRENT_LANG !== LANGUAGE.ENGLISH && (
              <Button startIcon={<LanguageIcon />} color='secondary' href={URLS.EN}>
                English
              </Button>
            )}
            {URLS.CURRENT_LANG !== LANGUAGE.FRENCH && (
              <Button startIcon={<LanguageIcon />} color='secondary' href={URLS.FR}>
                Français
              </Button>
            )}
            {URLS.CURRENT_LANG !== LANGUAGE.SPANISH && (
              <Button startIcon={<LanguageIcon />} color='secondary' href={URLS.ES}>
                Español
              </Button>
            )}
          </Box>
        </Stack>
      </Paper>
    </footer>
  )
}
