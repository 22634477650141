import AgentIcon from '@mui/icons-material/SupervisorAccount'
import { Box, Paper, Typography } from '@mui/material'
import relativeTime from 'dayjs/plugin/relativeTime'
import dayjs from 'dayjs'

dayjs.extend(relativeTime)

export default function AgentMessage({
  timeSentLocal,
  name,
  message,
}: ChatMessage): React.JSX.Element {
  return (
    <Paper sx={{ p: 2 }} variant='outlined'>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
        }}
      >
        <AgentIcon fontSize='large' />
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-between',
            flexGrow: 1,
            pl: 1,
          }}
        >
          <Typography variant='h6'>{name}</Typography>
          {timeSentLocal && (
            <Typography sx={{ ml: 2, color: 'textSecondary' }}>
              {dayjs(timeSentLocal).fromNow()}
            </Typography>
          )}
        </Box>
      </Box>
      <Typography sx={{ ml: 6 }}>{message}</Typography>
    </Paper>
  )
}
