import {
  Divider,
  Container as MuiContainer,
  type ContainerProps as MuiContainerProps,
  Paper as MuiPaper,
  type PaperProps as MuiPaperProps,
  Typography,
  type TypographyProps,
} from '@mui/material'

interface PaperProps extends MuiPaperProps {
  divider?: boolean
  title?: string
  titleProps?: TypographyProps
}

export function Paper({
  title,
  divider,
  children,
  titleProps = {},
  ...other
}: PaperProps): React.JSX.Element {
  const variant = titleProps?.variant ?? 'h1'

  return (
    <MuiPaper
      {...other}
      sx={{
        p: 1,
        mx: 0,
        my: 1,
        ...other.sx,
      }}
    >
      {title && (
        <Typography
          align='center'
          {...titleProps}
          variant={variant}
          sx={{
            color: variant === 'h1' ? 'title' : undefined,
            fontWeight: 'fontWeightBold',
            mb: 1,
            ...titleProps.sx,
          }}
        >
          {title}
        </Typography>
      )}
      {divider && (
        <Divider
          sx={{
            mb: 1,
            mx: 0,
          }}
        />
      )}
      {children}
    </MuiPaper>
  )
}

interface ContainerProps extends MuiContainerProps {
  divider?: boolean
  title?: string
  titleProps?: TypographyProps
}

export default function Container({
  sx,
  title,
  divider,
  children,
  titleProps,
  ...other
}: ContainerProps): React.JSX.Element {
  return (
    <MuiContainer sx={{ p: 1, ...sx }} maxWidth='lg' {...other}>
      {title && (
        <Paper title={title} divider={divider} titleProps={titleProps}>
          {children}
        </Paper>
      )}
      {!title && children}
    </MuiContainer>
  )
}
