import {
  Box,
  type BoxProps,
  DialogActions,
  type DialogActionsProps,
  DialogContent,
  type DialogContentProps,
  IconButton,
  Dialog as MuiDialog,
  type DialogProps as MuiDialogProps,
  Typography,
  useMediaQuery,
} from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import { useTheme } from '@mui/material/styles'

interface ExitButtonProps {
  onClose: (event: React.MouseEvent<HTMLButtonElement>) => void
  hasTitle: boolean
}
function ExitButton({ onClose, hasTitle }: ExitButtonProps): React.JSX.Element {
  return (
    <IconButton
      aria-label='close'
      sx={{
        zIndex: 1000,
        position: hasTitle
          ? {
              md: 'absolute',
            }
          : 'absolute',
        right: hasTitle
          ? {
              md: '-.625rem',
            }
          : {
              xs: '8px',
              md: '-.625rem',
            },
        top: hasTitle
          ? {
              md: '-.625rem',
            }
          : {
              xs: '8px',
              md: '-.625rem',
            },
        backgroundColor: hasTitle
          ? {
              md: 'primary.main',
            }
          : 'primary.main',
        border: hasTitle
          ? {
              md: '.125rem solid #fff',
            }
          : '.125rem solid #fff',
        width: hasTitle
          ? {
              md: '2rem',
            }
          : '2rem',
        height: hasTitle
          ? {
              md: '2rem',
            }
          : '2rem',
        color: hasTitle
          ? {
              xs: (theme) => theme.palette.grey[500],
              md: 'common.white',
            }
          : 'common.white',
        '&:hover': {
          backgroundColor: hasTitle
            ? {
                md: 'primary.dark',
              }
            : 'primary.dark',
        },
      }}
      onClick={onClose}
    >
      <CloseIcon />
    </IconButton>
  )
}

export interface DialogProps extends Omit<MuiDialogProps, 'onClose'> {
  ActionProps?: DialogActionsProps
  actions?: React.ReactNode
  ContentProps?: DialogContentProps
  hideCloseButton?: boolean
  onClose?: (
    event: React.MouseEvent<HTMLButtonElement>,
    reason: 'backdropClick' | 'escapeKeyDown' | 'titleCloseButtonClick',
  ) => void
  title?: string
  TitleProps?: BoxProps
}

export default function Dialog({
  ActionProps = {},
  actions,
  children,
  ContentProps = {},
  hideCloseButton,
  onClose,
  open = false,
  title,
  TitleProps = {},
  ...other
}: DialogProps): React.JSX.Element {
  const theme = useTheme()
  const fullscreen = useMediaQuery(theme.breakpoints.down('md'))

  function handleCloseButtonClick(event: React.MouseEvent<HTMLButtonElement>): void {
    if (onClose) onClose(event, 'titleCloseButtonClick')
  }

  return (
    <MuiDialog
      {...other}
      sx={{
        ...other.sx,
        '& .MuiDialog-paperFullScreen': {
          m: 0,
        },
      }}
      onClose={onClose}
      open={open}
      fullScreen={fullscreen}
    >
      {(!!title || !hideCloseButton) && (
        <Box
          {...TitleProps}
          sx={{
            display: 'flex',
            alignItems: 'center',
            p: title ? 1 : 0,
            ...TitleProps.sx,
          }}
        >
          {!!title && (
            <Typography fontWeight='fontWeightBold' noWrap variant='h2' sx={{ flexGrow: 1 }}>
              {title}
            </Typography>
          )}
          {!hideCloseButton && <ExitButton onClose={handleCloseButtonClick} hasTitle={!!title} />}
        </Box>
      )}
      <DialogContent dividers {...ContentProps} sx={{ p: 1, ...ContentProps.sx }}>
        {children}
      </DialogContent>
      {actions && <DialogActions {...ActionProps}>{actions}</DialogActions>}
    </MuiDialog>
  )
}
