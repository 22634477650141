import {
  Box,
  type BoxProps,
  Button,
  type DialogContentProps,
  IconButton,
  type IconButtonProps,
  Link,
  type LinkProps,
  type ButtonProps as MuiButtonProps,
} from '@mui/material'
import VideoIcon from '@mui/icons-material/OndemandVideo'
import { useState } from 'react'

import Dialog, { type DialogProps } from './dialog'

interface BaseDialogProps extends Omit<BoxProps, 'onSubmit'> {
  actionButtonText?: string | string[]
  actionType?: 'single' | 'double' | 'none'
  buttonContent?: React.ReactNode
  disabled?: boolean
  DialogContentProps?: DialogContentProps
  DialogProps?: Partial<DialogProps>
  hideCloseButton?: boolean
  onClose?: (event: React.MouseEvent<HTMLButtonElement>, reason: string) => void
  onlyCloseOnActionClick?: boolean
  onOpen?: () => void
  onSubmit?: (event: React.MouseEvent<HTMLButtonElement>) => void
  title?: string
}

export interface DialogButtonProps extends BaseDialogProps {
  buttonType?: 'button' | 'video'
  ButtonProps?: MuiButtonProps
}
export interface DialogLinkProps extends BaseDialogProps {
  buttonType?: 'link'
  ButtonProps?: LinkProps
}
export interface DialogIconProps extends BaseDialogProps {
  buttonType?: 'icon'
  ButtonProps?: IconButtonProps
}

export type ButtonDialogProps = DialogButtonProps | DialogLinkProps | DialogIconProps

export default function ButtonDialog({
  ButtonProps = {},
  DialogContentProps,
  DialogProps,
  actionButtonText,
  actionType = 'single',
  buttonContent,
  buttonType = 'button',
  children,
  onlyCloseOnActionClick,
  hideCloseButton,
  onClose,
  onOpen,
  onSubmit,
  title,
  ...other
}: ButtonDialogProps): React.JSX.Element {
  const [open, setOpen] = useState(false)

  function handleClickOpen(event: React.MouseEvent<HTMLElement>): void {
    setOpen(true)
    if (onOpen) onOpen()

    event.stopPropagation()
  }

  function handleClose(
    event: React.MouseEvent<HTMLButtonElement>,
    reason:
      | 'backdropClick'
      | 'escapeKeyDown'
      | 'titleCloseButtonClick'
      | 'actionClick'
      | 'submitClick',
  ): void {
    if (
      !onlyCloseOnActionClick ||
      (onlyCloseOnActionClick && (reason === 'actionClick' || reason === 'submitClick'))
    ) {
      if (onClose) onClose(event, reason)

      setOpen(false)
    }
  }

  function handleActionClose(event: React.MouseEvent<HTMLButtonElement>): void {
    handleClose(event, 'actionClick')
  }

  function handleSubmit(event: React.MouseEvent<HTMLButtonElement>): void {
    if (onSubmit) onSubmit(event)

    handleClose(event, 'submitClick')
  }

  function getActions(): React.ReactNode {
    switch (actionType) {
      case 'single':
        return (
          <Button onClick={handleSubmit}>
            {Array.isArray(actionButtonText) ? actionButtonText[0] : (actionButtonText ?? 'OK')}
          </Button>
        )
      case 'double':
        return [
          <Button key='negitive' onClick={handleActionClose} color='inherit'>
            {(Array.isArray(actionButtonText) && actionButtonText[1]) || 'NO'}
          </Button>,
          <Button key='positive' onClick={handleSubmit}>
            {(Array.isArray(actionButtonText) && actionButtonText[0]) || 'YES'}
          </Button>,
        ]
      case 'none':
        return undefined
      default:
        throw new Error('Unsupported actionType')
    }
  }
  function getButton(): React.ReactNode {
    switch (buttonType.toLowerCase()) {
      case 'icon':
        return (
          <IconButton size='large' {...(ButtonProps as IconButtonProps)} onClick={handleClickOpen}>
            {buttonContent}
          </IconButton>
        )
      case 'link':
        return (
          <Link
            {...(ButtonProps as LinkProps)}
            onClick={handleClickOpen}
            underline='hover'
            color='secondary'
            sx={{ cursor: 'pointer', ...ButtonProps.sx }}
          >
            {buttonContent}
          </Link>
        )
      case 'video':
        return (
          <Button
            {...(ButtonProps as MuiButtonProps)}
            onClick={handleClickOpen}
            startIcon={<VideoIcon />}
          >
            {buttonContent}
          </Button>
        )
      default:
        return (
          <Button {...(ButtonProps as MuiButtonProps)} onClick={handleClickOpen}>
            {buttonContent}
          </Button>
        )
    }
  }

  return (
    <Box component='span' {...other}>
      {getButton()}
      <Dialog
        onClose={handleClose}
        open={open}
        title={title}
        hideCloseButton={hideCloseButton}
        actions={getActions()}
        ContentProps={DialogContentProps}
        {...DialogProps}
      >
        {children}
      </Dialog>
    </Box>
  )
}
